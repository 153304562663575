/*all*/
.header .nav .menus a {
  white-space: nowrap;
}

.header .nav .menus {
  justify-content: space-between !important;
}

.ads-box-wrp {
  margin: 8px 0;
  display: none;
}
.ads-box-wrp img {
  width: 100%;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.middle-panel {
  width: 100%;
}

.notification {
  padding: 12px 5px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 4px;
  margin: 25px;
  color: #80172a;
  background-color: #fdd5dc;
  border-color: #fcc4ce;
}

/*desktop*/
@media screen and (min-width: 1025px) {
  body,
  p,
  td {
    font-size: 15px; /*font-smooth: never !important;
      -webkit-font-smoothing : none !important; */
    line-height: 1.2;
  }
  .footer .bottom,
  .scores .middle-panel .content .list .item .body > div span {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }

  .footer li a,
  .footer li {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }
  .text {
    font-size: 15px !important;
    line-height: 1.2 !important;
  }

  span.badge,
  .scores .middle-panel .content .list .item .head .left .first,
  span.first {
    min-width: 18px !important;
    height: auto !important;
    line-height: 1 !important;
    font-size: 11px !important;
    width: auto !important;
    padding: 2px 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  p.date,
  th,
  p.time,
  .scores .middle-panel .content .list .item .head .left .date-time,
  span.date-time,
  .scores .middle-panel .content .list .item .head .right .second {
    font-size: 14px;
    line-height: 1.2;
  }

  .pagination .paginator span {
    font-size: 15px !important;
    font-weight: 500 !important;
    width: auto !important;
    min-width: 23px;
    padding: 6px;
  }
  .lamp {
    width: 16px;
    height: 16px;
  }

  .cross {
    width: 14px;
    height: 14px;
  }
  .pagination {
    margin-top: 14px;
  }
  .btn,
  .home .middle-panel .top-show .right a,
  .support-tickets .middle-panel .header .bottom span {
    line-height: 1.2 !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .scores .middle-panel .content .list .item .head {
    gap: 6px;
  }
}

/*tablet*/
@media screen and (min-width: 960px) and (max-width: 1024px) {
  .content {
    width: 100% !important;
  }

  .scores,
  .home,
  .my-picks,
  .balance,
  .leaders,
  .consensus-picks,
  .computer-picks,
  .sportsbooks,
  .winners,
  .support-tickets,
  .pick-points,
  .faq,
  .rulls,
  .t-c,
  .profile {
    gap: 18px !important;
    padding: 24px 24px !important;
  }
  .header {
    padding: 24px 24px !important;
  }

  .header .nav .menus a {
    padding: 4px 2px !important;
  }
}

@media screen and (max-width: 1024px) {
  .left-panel {
    display: none;
  }
  .pagination p.page-info {
    font-weight: 500 !important;
  }
}

/*mobile up to 959*/
@media screen and (min-width: 768px) and (max-width: 959px) {
  .right-panel {
    display: none !important;
  }
  .content {
    width: 100% !important;
  }

  .middle-panel {
    width: 100% !important;
    min-width: 1px !important;
  }

  /*font face*/
  body,
  p,
  td.ticket {
    font-size: 14px !important; /*font-smooth: never !important;
              -webkit-font-smoothing : none !important;*/
    line-height: 1.2 !important;
  }
  td,
  .footer .bottom,
  .scores .middle-panel .content .list .item .body > div span {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }

  .footer li a,
  .footer li {
    font-size: 13px !important;
    line-height: 1.2 !important;
  }
  .text {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 959px) {
  .scores,
  .home,
  .my-picks,
  .balance,
  .leaders,
  .consensus-picks,
  .computer-picks,
  .sportsbooks,
  .winners,
  .support-tickets,
  .pick-points,
  .faq,
  .rulls,
  .t-c,
  .profile {
    gap: 16px !important;
    padding: 16px 16px !important;
  }
  p.date,
  th,
  p.time,
  .scores .middle-panel .content .list .item .head .left .date-time,
  span.date-time,
  .scores .middle-panel .content .list .item .head .right .second {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }
  .pagination .paginator span {
    font-size: 14px !important;
    font-weight: 500 !important;
    width: auto !important;
    min-width: 23px;
    padding: 12px;
  }

  /*font face*/
  body,
  p,
  td.ticket {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }
  td,
  .footer .bottom,
  .scores .middle-panel .content .list .item .body > div span {
    font-size: 11px !important;
    line-height: 1.2 !important;
  }

  .footer li a,
  .footer li {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }
  .text {
    font-size: 12px !important;
    line-height: 1.2 !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 600px) {
  .scores,
  .home,
  .my-picks,
  .balance,
  .leaders,
  .consensus-picks,
  .computer-picks,
  .sportsbooks,
  .winners,
  .support-tickets,
  .pick-points,
  .faq,
  .rulls,
  .t-c,
  .profile {
    gap: 8px !important;
    padding: 16px !important;
  }
  .content,
  .leagues,
  .support-tickets .middle-panel .header {
    border: none !important;
    padding: 0 !important;
    margin-top: 12px !important;
  }
  .header .top-bar .brand {
    /* padding-bottom: 6px !important; */
  }

  .header {
    padding: 16px 16px !important;
  }

  .header .top-bar .top-nav-wrapper .top-nav.auth {
    height: auto;
    gap: 8px;
  }

  .header .top-bar .top-nav-wrapper .top-nav.auth .link span {
    font-size: 11px !important;
    font-weight: 500 !important;
  }
  .support-tickets .middle-panel .header .info,
  .support-tickets .middle-panel .header .player,
  .support-tickets .middle-panel .header .tags,
  .support-tickets .middle-panel table {
    margin: 12px 0 !important;
  }
  .tabs span,
  .tabs a {
    font-size: 11px !important;
    padding: 8px 12px !important;
    margin-right: 8px !important;
  }

  .middle-panel .tabs {
    margin-top: 12px !important;
  }
  .leaders .middle-panel .tabs {
    padding: 10px 12px !important;
    margin: 0 !important;
  }
  .content .body {
    padding: 12px 12px !important;
  }
  span.item {
    font-size: 11px !important;
    padding: 6px 0 !important;
    line-height: 1.2 !important;
  }

  .slider {
    overflow-x: auto;
    white-space: nowrap;
    padding: 12px 16px !important;
    height: auto !important;
  }

  .slider svg {
    display: none;
  }
  .slider span {
    display: block !important;
  }
  table.time-group,
  .leaders table {
    display: inline-table;
  }
  .tabs span.active {
    color: white;
  }
  span.badge,
  .scores .middle-panel .content .list .item .head .left .first,
  span.first {
    min-width: 16px !important;
    font-size: 9px !important;
    width: auto !important;
    height: 16px !important;
    padding: 0px 4px !important;
  }

  .sportsbooks .middle-panel .content .book-item .head .left img {
    width: 24px;
    height: 24px;
  }

  table th,
  .leagues .league-group p.date {
    font-weight: 500 !important;
    font-size: 10px !important;
  }

  td {
    padding: 14px !important;
  }

  .home .middle-panel .top-show {
    padding: 0 !important;
  }

  .home .middle-panel .top-show .left {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    margin-bottom: 0px !important;
    align-content: center;
  }

  .home .middle-panel .top-show .left .item {
    border: none !important;
    width: auto !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    gap: 12px !important;
    /* width: 218px !important; */
    padding: 12px 16px !important;
  }

  .home .middle-panel .top-show .left .item img {
    width: 32px !important;
  }

  .home .middle-panel .top-show {
    border: solid 1px #f4e1e1 !important;
  }

  .home .middle-panel .top-show .left .item .info p,
  .my-picks .middle-panel .top-show .left .item .info p {
    font-size: 14px !important;
    font-weight: 600;
    white-space: nowrap;
  }
  .home .middle-panel .top-show .left .item .info .heading {
    font-size: 9px !important;
    font-weight: 500 !important;
    white-space: normal !important;
  }

  .home .middle-panel .top-show .right {
    padding-bottom: 12px;
  }
  .leagues .league-group p.date {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
}

@media screen and (min-width: 1px) and (max-width: 590px) {
  .winners .username {
    white-space: pre-line;
  }
  .footer .body .center a {
    font-size: 12px !important;
  }

  .footer .body .center a span {
    font-size: 14px !important;
  }

  .footer .body .center a {
    padding: 0 !important;
    height: 60px;
    gap: 12px !important;
  }

  .footer .body .center a img {
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 767px) {
  .fixed + div {
    padding-top: 130px !important;
  }
}

@media screen and (max-width: 375px) {
  /* .time-group-tables {
        display: block !important;
    } */
}

@media screen and (max-width: 320px) {
  .ads-box-wrp {
    display: block;
  }
  .top-show {
    display: flex;
    justify-content: center !important;
  }

  .home .middle-panel .top-show .left,
  .home .middle-panel .top-show .right {
    flex-grow: unset !important;
  }

  /* section.mobile-banner {
        position: sticky;
        left: 0;
    } */
}

@media only screen and (max-width: 768px) {
  /* .leagues .league-group:nth-child(1) .time-group:nth-child(3),
  .leagues .league-group:nth-child(2) .time-group:nth-child(2),
  .leagues .league-group:nth-child(2) .time-group:nth-child(4) {
    margin-bottom: 30px !important;
    background-color: #f4e1e1;
  } */

  /* .leagues .league-group:nth-child(1) .time-group:nth-child(3) tbody tr td,
  .leagues .league-group:nth-child(2) .time-group:nth-child(2) tbody tr td,
  .leagues .league-group:nth-child(2) .time-group:nth-child(4) tbody tr td {
    border-color: #a3a3a3 !important;
  } */
}

.chatroom + .footer .footer-mobile > .right {
  display: none !important  ;
}

.header a.mobileLogo span {
  font-weight: 800;
  color: #fa8c28;
}
.header .show-mobile .menus .logo-mobile a span {
  font-weight: 800;
  color: #fa8c28;
}
.header .show-mobile .menus .logo-mobile a {
  font-size: 24px!important;
}
.contnt-fot span {
  font-weight: 800;
  color: #fa8c28;
}

.logoClass span {
  font-weight: 800;
  color: #fa8c28;
}

.revenue-info h2 { 
    font-size: 18px;
    margin-bottom: 15px;
    text-transform: capitalize;
    line-height: 26px;
}

.revenue-info p {
    margin: 12px 0;
    font-size: 14px;
    opacity: 0.7;
    line-height: 21px;
}

.replenish p {
  font-size: 16px; 
  line-height: 21px;
}
.previewContent p {
  margin-top:10px;
}

.bet-game a {
  text-decoration: none;
  color:#000;
}